<template>
  <div class="merchants">
    <PageHeader :border="false" title="产品首营流程" center>
      <Steps :step="active" :steps-data="stepsData" />
    </PageHeader>
    <MatetialEdit ref="MatetialEdit" :i-d-x="IDX" :request-type="requestType" :mateial-info="mateialInfo" />
      <!-- 首营的证照列表 -->
    <editCertifiList ref="editCertifiList" :IDX="IDX" :RequestType="requestType"/>
  </div>
</template>
<script>
import productEdit from '@/minxin/product/productEdit.js'
export default productEdit
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>
