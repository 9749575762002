<template>
  <div>
    <div v-loading.fullscreen.lock="editProductLoading" class="p-lr-20">
      <div class="InfoTitleBox m-tb-10"><i class="el-icon-caret-right" /> 申请信息</div>
      <el-form ref="ruleForm" label-width="100px" label-position="right" :inline="true" :rules="rules" :model="form" size="mini" class="demo-form-inline FirstBusiness">
        <el-form-item label="申请日期" prop="ApplyDate" class="eachitem">
          <el-date-picker v-model="form.ApplyDate" type="date" value-format="yyyy-MM-dd" placeholder="默认发起审核日期" :disabled="true" />
        </el-form-item>
        <el-form-item label="申请部门" class="eachitem">
          <el-select v-model="form.DepartID" placeholder="申请部门" @change="changeDept">
            <el-option v-for="item in DepartList" :key="item.IDX" :label="item.GroupName" :value="item.IDX" />
            <el-option label=" " :disabled="true" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="申请人" class="eachitem">
          <el-select v-model="form.ApplyPersonID" placeholder="申请人">
            <el-option v-for="(item, index) in UserList" :key="index" :label="item.NickName" :value="item.UserID" />
          </el-select>
          <!-- <el-input placeholder="请选择申请人" v-model="form.ApplyPersonName" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search" @click="selUser(1)"></el-button>
                </el-input> -->
        </el-form-item>
        <el-form-item label="填表人" class="eachitem"><el-input
          v-model="UserInfo.NickName"
          size="mini"
          :disabled="true"
        />
          <!-- <el-select v-model="form.FillFormUserID" placeholder="填表人">
                <el-option label="小芳" value="shanghai" />
                <el-option label="小王" value="beijing" />
              </el-select> -->
        </el-form-item>
        <div class="InfoTitleBox m-tb-10"><i class="el-icon-caret-right" /> 产品基本信息</div>
        <div class="InfoList1">
          <el-form-item label="物料名称" prop="MaterialName" class="eachitemedit">
            <el-input
              v-model="form.MaterialName"
              size="mini"
              placeholder="请输入物料名称"
            />
          </el-form-item>
          <el-form-item label="物料简称" class="eachitemedit" style="margin-bottom:10px!important">
            <el-input
              v-model="form.NickName"
              size="mini"
              placeholder="请输入物料简称"
            />
          </el-form-item>
          <el-form-item label="物料编号" prop="InvCode" class="eachitemedit">
            <el-input
              v-model="form.InvCode"
              size="mini"
              placeholder="系统分配"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="物料类型" prop="ApplyDate" class="eachitemedit" style="margin-bottom:10px!important">
            <el-cascader
              v-model="form.checkedMaterialType"
              :options="InvTypeList"
              :props="{ checkStrictly: true }"
              filterable
              clearable
              @change="MaterialTypehandleChange"
            />
          <!-- <el-select v-model="form.MaterialTypeID" placeholder="">
            <el-option label="类型1" :value=1 />
            <el-option label="类型2" :value=2 />
            <el-option label=" " :disabled="true" :value=0 />
          </el-select> -->
          </el-form-item>
          <el-form-item label="物料分类" class="eachitemedit">
            <el-select v-model="form.InvTypeID" placeholder="" filterable>
              <el-option v-for="matertypeitem in materialTypeList" :key="matertypeitem.IDX" :label="matertypeitem.InvTypeName" :value="matertypeitem.IDX" />
              <el-option label=" " :disabled="true" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="品牌" class="eachitemedit">
            <el-autocomplete
              v-model="form.BrandName"
              :fetch-suggestions="getbrand"
              placeholder="请选择品牌名称"
              @select="Select_brand"
            />
          </el-form-item>
          <el-form-item label="原产地" class="eachitemedit">
            <!-- <el-input v-model="form.SourceAreaName" placeholder="请选择原产地" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="selSource(1)" />
          </el-input> -->
            <el-autocomplete
              v-model="form.SourceAreaName"
              :fetch-suggestions="getsource"
              placeholder="请选择原产地"
              @select="Select_source"
            />
          </el-form-item>
          <el-form-item label="规格/型号" class="eachitemedit">
            <el-input
              v-model="form.Specification"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="最小包装" class="eachitemedit">
            <el-input
              v-model="form.MinPacking"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="存货代码" class="eachitemedit">
            <el-input
              v-model="form.StorageNo"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="单位" prop="ApplyDate" class="eachitemedit">
            <el-input
              v-model="form.StockUnitN"
              size="mini"
              placeholder=""
            />
          <!-- <el-select v-model="form.InvTypeCode" placeholder="" filterable>
                <el-option    v-for="item in UnitList"  :key="item.IDX" :label="matertypeitem.InvTypeName" :value="matertypeitem.InvTypeCode" />
              </el-select> -->
          </el-form-item>
          <el-form-item label="产品有效期" prop="ApplyDate" class="eachitemedit">
            <el-input
              v-if="!form.isLong && RequestType === 1"
              v-model="form.ShelfLifeNum"
              size="mini"
              style="width:calc(100% - 180px);margin-right:5px"
              placeholder=""
            />
            <el-input
              v-if="!form.isLong && RequestType === 2"
              v-model="form.ShelfLifeNums"
              size="mini"
              style="width:calc(100% - 180px);margin-right:5px"
              placeholder=""
            />
            <el-select
              v-if="!form.isLong"
              v-model="form.ShelfLifeType"
              placeholder=""
              style="width:70px;margin-right:5px"
            >
              <el-option label="年" :value="1" />
              <el-option label="月" :value="2" />
              <el-option label="日" :value="3" />
            </el-select>
            <el-checkbox v-model="form.isLong" style="width:auto!important" @change="changelong">长期有效</el-checkbox>
          </el-form-item>
          <el-form-item label="运输条件" class="eachitemedit">
            <el-select v-model="form.TransportCondition" placeholder="" filterable>
              <el-option v-for="item in ConditionList" :key="item.IDX" :label="item.StorageConditionName" :value="item.IDX" />
              <el-option label=" " :disabled="true" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="原厂编号" class="eachitemedit">
            <el-input v-model="form.ManuPartNum" size="mini" placeholder="" />
          </el-form-item>
        </div>
        <br>
      </el-form>
      <el-form ref="ruleForm" label-width="220px" label-position="right" abel-position="left" :inline="true" :rules="rules" :model="form" class="demo-form-inline InfoBoxTwo" size="mini">
        <div class="InfoList2">
          <el-form-item label="注册证号/批准文号/备案凭证号" prop="RegisterNo" class="eachitemedit2" style="margin-bottom:10px!important">
            <el-input
              v-model="form.RegisterNo"
              size="mini"
              placeholder="如果不在医疗器械范畴，请填写不属于医疗器械管理"
            />
          </el-form-item>
          <el-form-item label="注册证起始日期" class="eachitemedit2">
            <el-date-picker
              v-model="form.RegisterStart"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder=""
            />
          <!-- <el-input
                v-model="form.RegisterStart"
                size="mini"
                placeholder=""
                style="width:300px"
              /> -->
          </el-form-item>
          <br>
          <el-form-item label="注册证截止日期" prop="RegisterEnd" class="eachitemedit2" style="margin-bottom:10px!important">
            <el-date-picker
              v-model="form.RegisterEnd"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder=""
              style="width:calc(100% - 95px)"
            />
            <!-- <el-input
                v-model="form.RegisterEnd"
                size="mini"
                placeholder=""
                style="width:300px"
              /> -->
            <el-checkbox v-model="form.isLong2" style="width:80px!important;margin-left:10px; display:inline-block" @change="islongchangeend">长期有效</el-checkbox>
          </el-form-item>
          <el-form-item label="医疗器械注册人" class="eachitemedit2">
            <el-input
              v-model="form.RegisterPerson"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <br>
          <el-form-item label="生产厂家" class="eachitemedit2">
            <el-input
              v-model="form.ProductFactory"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="药品上市持有许可人" class="eachitemedit2">
            <el-input
              v-model="form.MaterialPermissionPerson"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <br>
          <el-form-item label="生产企业许可证号" class="eachitemedit2">
            <el-input
              v-model="form.ProductLicenseNo"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="物料默认所在仓库" class="eachitemedit2">
            <!-- <el-input
                v-model="form.StockCode"
                size="mini"
                placeholder=""
                style="width:300px"
              /> -->
            <el-select v-model="form.StockCode" placeholder="" filterable>
              <!-- <el-option label="3000" :value="3000" /> -->
              <el-option v-for="(item, index) in locatList" :key="index" :label="item.StockCode" :value="item.StockCode" />
            </el-select>
          </el-form-item>
          <br>
          <el-form-item label="储存条件" class="eachitemedit2">
            <el-select v-model="form.StorageConditionID" placeholder="" filterable>
              <el-option v-for="item in ConditionList" :key="item.IDX" :label="item.StorageConditionName" :value="item.IDX" />
              <el-option label=" " :disabled="true" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="DI码" class="eachitemedit2">
            <el-input
              v-model="form.DINum"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <br>
          <el-form-item label="备注">
            <el-input
              v-model="form.MaterialRemark"
              size="mini"
              placeholder=""
            />
          </el-form-item>
        </div>
        <div class="InfoTitleBox m-tb-20"><i class="el-icon-caret-right" /> 财务信息</div>
        <div class="InfoBoxTwo1 p-bottom-20">
          <el-form-item label="产品税率(%)" size="mini">
            <el-select v-model="form.TaxType" placeholder="" style="width:46%">
              <el-option label="生物" :value="1" />
              <el-option label="非生物" :value="2" />
              <!--<el-option label=" " :disabled="true" :value="0" />-->
            </el-select>
            <el-select v-model="form.Tax" placeholder="" style="width:46%" class="m-left-10">
              <el-option label="17" :value="0.17" />
              <el-option label="16" :value="0.16" />
              <el-option label="13" :value="0.13" />
              <el-option label="11" :value="0.11" />
              <el-option label="10" :value="0.1" />
              <el-option label="6" :value="0.06" />
              <el-option label="3" :value="0.03" />
              <el-option label="1" :value="0.01" />
              <el-option label="0" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="计划价格(元)" size="mini">
            <el-input
              v-model="form.PlanPrice"
              style="width:100%"
              size="mini"
              placeholder=""
            />
          </el-form-item>
          <el-form-item label="发布价格(元)" size="mini">
            <el-input
              v-model="form.Price"
              style="width:100%"
              size="mini"
              placeholder=""
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 选择申请人 -->
    <el-dialog title="" :visible.sync="selUserVisible">
      <PageHeader class="m-top-10" :border="false" title="选择申请人" />
      <el-form :inline="true">
        <el-form-item label="申请人姓名" style="width:300px">
          <el-input v-model="selUsername" />
        </el-form-item>
        <div style="float:right">
          <el-button @click="checkUser">选择数据</el-button>
          <el-button type="primary" @click="getUserList">查询</el-button>
        </div>
      </el-form>
      <el-table
        ref="UserTable"
        v-loading="UserListloading"
        :data="UserList"
        highlight-current-row
        @current-change="UserChage"
      >
        <el-table-column property="UserID" label="用户ID" />
        <el-table-column property="NickName" label="用户昵称" />
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :background="true"
          :total="UserListTotal"
          :current-page="UserListParas.PageIndex"
          :page-size="UserListParas.PageSize"
          layout="prev, pager, next, jumper"
          @current-change="UserListhandleCurrentChange"
        />
      </div>
    </el-dialog>
    <!-- 选择原产地 -->
    <el-dialog title="" :visible.sync="selSourceVisible">
      <PageHeader class="m-top-10" :border="false" title="选择原产地" />
      <el-form :inline="true">
        <el-form-item label="原产地名称" style="width:300px">
          <el-input v-model="selSourcename" />
        </el-form-item>
        <div style="float:right">
          <el-button @click="checkSource">选择数据</el-button>
          <el-button type="primary" @click="getSourceList">查询</el-button>
        </div>
      </el-form>
      <el-table
        ref="SourceTable"
        v-loading="SourceListloading"
        :data="SourceList"
        highlight-current-row
        @current-change="SourceChage"
      >
        <el-table-column property="IDX" label="原产地id" />
        <el-table-column property="SourceAreaName" label="原产地名称" />
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import MatetialEdit from '@/minxin/product/MatetialEdit.js'
export default MatetialEdit
</script>
<style scoped lang='scss'>
@import '@/style/index.scss';
@import '@/style/search.scss';
@import '@/style/product.scss';
</style>
<style lang='scss'>
</style>
