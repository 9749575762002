import $post from '@/request/productApis.js'
const MatetialEdit = {
  name: 'MatetialEdit1',
  components: {
  },
  props: {
    IDX: {
      type: Number,
      default: 0
    }, // 产品id
    RequestType: {
      type: Number,
      default: 0
    }, // 1： 首营  2：列表\
    mateialInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // IDX: 0,
      UserInfo: {}, // 人员信息
      editProductLoading: false,
      rules: {
        ApplyDate: [
          { required: true, message: '请选择申请日期', trigger: 'blur' }
        ],
        MaterialName: [
          { required: true, message: '请输入物料名称', trigger: 'blur' }
        ],
        InvCode: [
          { required: true, message: '请输入物料编号', trigger: 'blur' }
        ],
        RegisterNo: [
          { required: true, message: '请输入注册证号', trigger: 'blur' }
        ],
        RegisterEnd: [
          { required: true, message: '请选择注册证截止日期', trigger: 'blur' },
          { required: true, message: '请选择注册证截止日期', trigger: 'change' }
        ]
      },
      form: {
        IDX: 0,
        ApplyDate: '',
        DepartID: null,
        DepartNames: '',
        FillFormUserID: '',
        ApplyPersonID: null,
        ApplyPersonName: null,
        MaterialName: null,
        NickName: null,
        InvCode: null,
        MaterialTypeID: null,
        MaterialSubTypeID: null,
        InvTypeID: null,
        InvTypeCode: null,
        BrandID: 0,
        BrandName: '',
        SourceAreaID: null,
        SourceAreaName: null,
        Specification: null,
        MinPacking: null, // 最小包装
        StorageNo: null, // 库存代码
        StockUnitN: null, // 单位
        ShelfLifeNum: null,
        ShelfLifeNums: null, // 产品有效期
        ShelfLifeType: null, // 有效期状态：0 长期有效，1 年，2 月，3 日（新增）
        isLong: null, // 产品有效期是否长期
        TransportCondition: null, // 运输条件
        ManuPartNum: null,
        RegisterNo: null, // 注册证号/批准文号/备案凭证号
        RegisterStart: null,
        RegisterEnd: null,
        isLong2: false,
        RegisterCardType: null, // 注册证是否是长期有效 0: 否 1：是
        RegisterPerson: null, // 医疗器械注册人
        ProductFactory: null,
        MaterialPermissionPerson: null,
        ProductLicenseNo: null,
        StockCode: null,
        StorageConditionID: null,
        DINum: null,
        MaterialRemark: null,
        Tax: null, // 产品税率
        TaxType: null, // 税率类别
        PlanPrice: null,
        Price: null,
        // 选中的物料类型
        checkedMaterialType: null,
        // MaterialID 物料id
        MaterialID: 0,
        CertificateListSave: []
      },
      InvTypeList: [], // 物料分类
      materialTypeList: [{
        IDX: 1,
        InvTypeCode: '2312',
        InvTypeName: '312312'
      }], // 物料类型
      DepartList: [], // 部门
      ConditionList: [], // 储存 运输条件
      // 选择申请人
      selUsername: '',
      selUserVisible: false,
      UserList: [], // 人员
      UserListloading: false,
      UserListTotal: 0,
      UserCurrentRow: null,
      UserListParas: {
        PageIndex: 1,
        PageSize: -1,
        Name: '',
        permissionGroupInfoId: -1 // 部门id
      },
      // 选择原产地
      selSourcename: '',
      selSourceVisible: false,
      SourceList: [], // 原产地
      SourceListloading: false,
      SourceListTotal: 0,
      SourceCurrentRow: null,
      SourceListParas: {
        PageIndex: 1,
        PageSize: 5
      },
      // 品牌列表
      branList: [],
      locatList: [] // 仓位列表
    }
  },
  created() {
    this.getUserinfo()
  },
  mounted() {
    // this.InitEdit()
    if (this.IDX && this.IDX > 0) {
      this.getProductDetaisInfo(this.IDX)
    } else if (this.IDX === -1024) {
      // 初始化数据
      this.initFromMaterialInfo()
    }
  },
  methods: {
    initFromMaterialInfo() {
      this.form.MaterialID = this.mateialInfo.MaterialID
      this.form.MaterialName = this.mateialInfo.MaterialName
      this.form.NickName = this.mateialInfo.NickName
      this.form.InvCode = this.mateialInfo.InvCode
      this.form.InvTypeID = this.mateialInfo.InvTypeID
      this.form.MaterialTypeID = this.mateialInfo.MaterialTypeID
      this.form.MaterialSubTypeID = this.mateialInfo.MaterialSubTypeID
      //
      this.form.BrandID = this.mateialInfo.BrandID
      this.form.BrandName = this.mateialInfo.BrandName
      this.form.SourceAreaID = this.mateialInfo.SourceAreaID
      this.form.SourceAreaName = this.mateialInfo.SourceAreaName
      this.form.Specification = this.mateialInfo.Specification
      //
      this.form.StockUnitN = this.mateialInfo.StockUnitN
      this.form.ManuPartNum = this.mateialInfo.ManuPartNum
      this.form.RegisterNo = this.mateialInfo.RegisterNo
      this.form.RegisterStart = this.mateialInfo.RegisterStart
      this.form.RegisterEnd = this.mateialInfo.RegisterEnd
      this.form.RegisterStart = this.mateialInfo.StorageConditionID
      // 处理物料类型
      this.form.checkedMaterialType = []
      if (this.form.MaterialTypeID > 0) {
        this.form.checkedMaterialType.push(this.form.MaterialTypeID)
        if (this.form.MaterialSubTypeID > 0) {
          this.form.checkedMaterialType.push(this.form.MaterialSubTypeID)
        }
      }
    },
    // 选中物料类型
    MaterialTypehandleChange(val) {
      console.log(val)
      this.form.MaterialTypeID = null
      this.form.MaterialSubTypeID = null
      if (val && val.length > 0) {
        this.form.MaterialTypeID = val[0]
        this.form.MaterialSubTypeID = val[1]
      }
    },
    // 产品有效期是否选择长期
    changelong(val) {
      if (val) {
        this.form.ShelfLifeType = 0
        this.form.ShelfLifeNums = null
        this.form.ShelfLifeNum = null
      } else {
        this.form.ShelfLifeType = null
        this.form.ShelfLifeNums = null
        this.form.ShelfLifeNum = null
      }
    },
    // 获取仓位接口
    getCWList() {
      const _this = this
      _this.$api.GetStockCodeList({ KeyWords: '', PageIndex: 1, PageSize: -1 }).then((res) => {
        console.log(res)
        if (res.RetCode === '0') {
          _this.locatList = res.Message
        } else {
          console.log('获取仓位列表出错： ' + res.RetMsg)
        }
      })
    },
    getUserinfo() {
      const _this = this
      _this.editProductLoading = true
      $post.getUserInfo().then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          _this.UserInfo = res.Message
          _this.InitEdit()
        }
      })
    },
    // 获取品牌
    getbrand(queryString, cb) {
      const _this = this
      _this.branList = []
      const _para = {
        KeyWords: queryString,
        PageIndex: 1,
        PageSize: 5
      }
      $post.GetBrandListForFirstCamp(_para).then(res => {
        if (res.RetCode === '0') {
          if (!res.Message || res.Message.length < 1) {
            _this.form.BrandID = 0
          } else {
            cb(res.Message)
          }
        } else {
          console.log('获取品牌名称出错')
        }
      })
    },
    Select_brand(val) {
      console.log(val)
      if (val) {
        this.form.BrandID = val.IDX
        this.form.BrandName = val.value
      } else {
        this.form.BrandID = 0
      }
    },
    // 勾选长期有效
    islongchangeend(val) {
      if (val) {
        this.form.RegisterEnd = '9999-12-31'
      } else {
        this.form.RegisterEnd = ''
      }
    },
    // 申请部门改变时
    changeDept(val) {
      this.form.ApplyPersonID = null
      this.form.ApplyPersonName = null
      this.DepartList.forEach(element => {
        if (element.IDX === val) {
          this.form.DepartNames = element.GroupName
          return false
        }
      })
      this.UserListParas.permissionGroupInfoId = this.form.DepartID
      this.getUserList()
    },
    Turn(url) {
      this.$router.push(url)
    },
    // 初始化编辑页
    InitEdit() {
      this.editProductLoading = true
      this.getNowTime() // 当前日期
      this.getMaterialType() // 物料类型
      this.getInvType() // 物料分类
      this.getDepartList() // 部门列表
      this.getConditionList() // 存储/运输条件
      this.getCWList() // 获取仓位接口
      if (!this.IDX || this.IDX < 1) {
        // 新增产品时 默认申请人，申请部门
        this.form.DepartID = null
        if (this.UserInfo.PermissionGroupID && this.UserInfo.PermissionGroupID > 0) {
          this.form.DepartID = this.UserInfo.PermissionGroupID // ID-PermissionGroupID
          this.form.DepartNames = this.UserInfo.GroupName
          // 获取该部门下的人员列表
          this.UserListParas.permissionGroupInfoId = this.form.DepartID
        }
        this.getUserList()
        this.form.ApplyPersonID = this.UserInfo.UserID
        this.form.ApplyPersonName = this.UserInfo.NickName
        this.form.FillFormUserID = this.UserInfo.UserID
      } else {
        if (this.UserInfo.PermissionGroupID && this.UserInfo.PermissionGroupID > 0) {
          this.UserListParas.permissionGroupInfoId = this.UserInfo.PermissionGroupID
        }
        this.getUserList()
        this.editProductLoading = false
      }
    },
    getProductDetaisInfo(_idx) {
      const _this = this
      _this.editProductLoading = true
      if (_this.RequestType === 1) {
        $post.getProductInfo({ FCMIdx: _idx }).then(res => {
          if (res.RetCode === '0') {
            _this.form = res.Message
            _this.dealproductInfo(_this)
          } else {
            _this.$message.Error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'ProductList1' })
          }
        })
      } else if (_this.RequestType === 2) {
        $post.GetMaterialRefCompanyDeatil({ FCMIdx: _idx }).then(res => {
          if (res.RetCode === '0') {
            _this.form = res.Message
            _this.dealproductInfo(_this)
          } else {
            _this.$message.Error('获取产品信息出错： ' + res.RetMsg)
            _this.$router.push({ name: 'productInfoList1' })
          }
        })
      }
    },
    // 处理查询的数据
    dealproductInfo(_this) {
      _this.editProductLoading = false
      if (_this.form.ShelfLifeType === 0) {
        _this.$set(_this.form, 'isLong', true)
      } else {
        _this.$set(_this.form, 'isLong', false)
      }
      if (_this.form.RegisterCardType === 1) {
        _this.$set(_this.form, 'isLong2', true)
      } else {
        _this.$set(_this.form, 'isLong2', false)
      }
      // 处理物料类型
      if (_this.form.MaterialTypeID > 0) {
        _this.$set(_this.form, 'checkedMaterialType', [_this.form.MaterialTypeID])
        if (_this.form.MaterialSubTypeID > 0) {
          _this.form.checkedMaterialType.push(_this.form.MaterialSubTypeID)
        }
      }
    },
    // 选择原产地
    checkSource() {
      this.form.SourceAreaID = this.SourceCurrentRow.IDX
      this.form.SourceAreaName = this.SourceCurrentRow.SourceAreaName
      this.selSourceVisible = false
    },
    // 获取原产地
    getsource(queryString, cb) {
      const _this = this
      const _para = {
        keyWords: queryString,
        PageIndex: 1,
        PageSize: 5
      }
      $post.getSorceList(_para).then((res) => {
        if (res.RetCode === '0') {
          if (!res.Message || res.Message.length < 1) {
            _this.form.SourceAreaID = 0
          } else {
            cb(res.Message)
          }
        } else {
          console.log('获取原产地出错')
        }
      })
    },
    // 选择原产地
    Select_source(val) {
      console.log(val)
      if (val) {
        this.form.SourceAreaID = val.IDX
        this.form.SourceAreaName = val.SourceAreaName
      } else {
        this.form.SourceAreaID = 0
      }
    },
    selSource() {
      this.SourceCurrentRow = null
      this.selSourceVisible = true
      this.SourceList = []
      this.getSourceList()
    },
    SourceChage(val) {
      console.log(val)
      this.$refs.SourceTable.setCurrentRow(val)
      this.SourceCurrentRow = val
    },
    // 获取原产地
    getSourceList() {
      const _this = this
      _this.getTableDataAsync(2)
    },
    // 选择申请人
    checkUser() {
      this.form.ApplyPersonID = this.UserCurrentRow.UserID
      this.form.ApplyPersonName = this.UserCurrentRow.NickName
      this.selUserVisible = false
    },
    selUser() {
      this.UserCurrentRow = null
      this.selUserVisible = true
      this.UserList = []
      this.getUserList()
    },
    UserChage(val) {
      console.log(val)
      this.$refs.UserTable.setCurrentRow(val)
      this.UserCurrentRow = val
    },
    UserListhandleCurrentChange(val) {
      this.UserListParas.PageIndex = val
      this.getUserList()
    },
    // 获取人员
    getUserList() {
      const _this = this
      _this.getTableDataAsync(3)
    },
    // 获取储存 运输条件
    getConditionList() {
      const _this = this
      $post.getConditionList().then((res) => {
        _this.ConditionList = res.Message
      })
    },
    // 获取部门
    getDepartList() {
      const _this = this
      $post.getDepartList().then((res) => {
        _this.DepartList = res.Message
      })
    },
    // 获取物料类型
    getMaterialType() {
      const _this = this
      $post.getMaterialType().then((res) => {
        _this.InvTypeList = res.Message
      })
    },
    // 获取物料分类
    getInvType() {
      const _this = this
      $post.getInvTypeList().then((res) => {
        _this.materialTypeList = res.Message
      })
    },
    getNowTime() {
      var now = new Date()
      var year = now.getFullYear() // 得到年份
      var month = now.getMonth() // 得到月份
      var date = now.getDate() // 得到日期
      month = month + 1
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      this.form.ApplyDate = defaultDate
      // this.$set(this.form.ApplyDate, 'date', defaultDate)
    },
    saveComfirm() {
      this.$confirm('确认提交信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.save()
      }).catch(() => {
      })
    },
    save() {
      const _this = this
      let isGoing = true
      if (_this.form.StockUnitN && _this.form.StockUnitN.length > 0) {
        const enc = new TextEncoder('utf-8')
        if (enc.encode(_this.form.StockUnitN).length > 10) {
          _this.$message('产品单位长度不能超过10个英文字符或4个中文汉字')
          return false
        }
      }
      if (_this.form.ShelfLifeType === null) {
        _this.$message('请选择产品有效期时效单位')
        return false
      }
      if (_this.form.ShelfLifeType !== 0) {
        if (_this.RequestType === 1) {
          if (!_this.form.ShelfLifeNum || _this.form.ShelfLifeNum <= 0) {
            _this.$message('请填写产品有效期')
            return false
          }
        }
        if (_this.RequestType === 2) {
          if (!_this.form.ShelfLifeNums || _this.form.ShelfLifeNums <= 0) {
            _this.$message('请填写产品有效期')
            return false
          }
        }
      }
      _this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // 信息校验成功 调用接口
          isGoing = true
        } else {
          _this.$message('请完整填写信息')
          console.log('error submit!!')
          isGoing = false
        }
        if (!isGoing) {
          return false
        }
        _this.editProductLoading = true
        if (_this.form.isLong) {
          _this.form.ShelfLifeType = 0
        }
        if (!_this.form.ShelfLifeType) {
          // 默认为长期
          _this.form.ShelfLifeType = 0
        }
        if (_this.form.isLong2) {
          _this.form.RegisterCardType = 1
        } else {
          _this.form.RegisterCardType = 0
        }
        // 处理证照信息
        _this.form.CertificateListSave = _this.$parent.$refs.editCertifiList.materialdetaisinfo.MaterialCertificateList
        if (_this.RequestType === 1) {
          _this.dosubmit()
        } else if (_this.RequestType === 2) {
          _this.dosubmit2()
        }
      })
    },
    dosubmit() {
      const _this = this
      $post.EditProductInfo(_this.form).then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          if (_this.$parent.SaveOrLanch === 2) {
            // 发起审核
            _this.form.IDX = res.Message
            _this.$parent.$refs.editCertifiList.materialdetaisinfo = _this.form
            _this.$parent.$refs.editCertifiList.LaunchApproval()
          } else if (_this.$parent.SaveOrLanch === 3) {
            // 重新发起
            _this.form.IDX = res.Message
            _this.$parent.$refs.editCertifiList.materialdetaisinfo = _this.form
            _this.$parent.$refs.editCertifiList.reLaunchApproval()
          } else {
            _this.$message.success('保存成功！')
            _this.$router.push({ name: 'productEdit1', params: { type: 1, idx: res.Message }})
          }
          // _this.confrmaftersave(_this, res.Message)
        } else {
          _this.$message(res.RetMsg)
        }
      })
    },
    dosubmit2() {
      const _this = this
      $post.EditFirstCampmMaterialRefCompany(_this.form).then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          // 弹出选择框
          _this.$message.success('保存成功！')
          _this.$router.push({ name: 'productEdit1', params: { type: 2, idx: res.Message }})
          // _this.confrmaftersave(_this, res.Message)
        } else {
          _this.$message(res.RetMsg)
        }
      })
    },
    confrmaftersave(_this, idx) {
      _this.$confirm('保存成功', '提示', {
        confirmButtonText: '上传资质',
        cancelButtonText: '返回列表',
        type: 'success',
        center: true
      }).then(() => {
        // 长传资质
        _this.$router.push({
          name: 'uploadProductFiles1',
          params: { type: _this.RequestType, idx: idx }
        })
      }).catch(() => {
        if (_this.RequestType === 1) {
          _this.$router.push({ name: 'ProductList1' })
        } else if (_this.RequestType === 2) {
          _this.$router.push({ name: 'productInfoList1' })
        }
      })
    },
    cancle() {
      this.$router.push('/product/productlist')
    },
    getTableDataAsync(type = 1) {
      const _this = this
      console.log(type)
      switch (type) {
        case 1: {
          _this.tableDataloading = true
          $post.getFistProductList(_this.listParas).then((resp) => {
            _this.tableDataloading = false
            _this.tableData = resp.Message
            _this.tableTotalSize = resp.Total
          })
          break
        }
        case 2: {
          _this.SourceListloading = true
          $post.getSorceList(_this.SourceListParas).then((resp) => {
            _this.SourceListloading = false
            _this.SourceList = resp.Message
            _this.SourceListTotal = resp.Total
          })
          break
        }
        case 3: {
          _this.editProductLoading = true
          $post.getUserList(_this.UserListParas).then((resp) => {
            _this.editProductLoading = false
            _this.UserList = resp.Message
            _this.UserListTotal = resp.Total
          })
          break
        }
        default: {
          console.log(type + ' 未找到匹配数据源')
          return
        }
      }
    }
  }
}
export default MatetialEdit
