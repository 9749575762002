
import $post from '@/request/productApis.js'
import PageHeader from '@/components/PageHeader.vue'
import Steps from '@/components/steps'
import MatetialEdit from '@/views/Product/MatetialEdit.vue'
import editCertifiList from '@/views/Product/editCertifiList.vue'
const productEdit = {
  name: 'productEdit1',
  components: {
    Steps,
    PageHeader,
    MatetialEdit,
    editCertifiList,
    SaveOrLanch: 1 //  1： 保存 2 ：发起审核 ，3：重新发起审核
    // searchView
  },
  data() {
    return {
      IDX: 0, // 产品id
      UserInfo: {}, // 人员信息
      editProductLoading: false,
      keywords: '',
      active: 1,
      stepsData: [
        {
          id: '1',
          name: '新增商品',
          icon: 'el-icon-document-add'
        },
        {
          id: '2',
          name: '完善商品信息',
          icon: 'el-icon-edit'
        },
        {
          id: '3',
          name: '上传商品证照',
          icon: 'el-icon-upload'
        },
        {
          id: '4',
          name: '等待审核',
          icon: 'el-icon-s-promotion'
        }
      ],
      requestType: 0, // 1 首营 2：列表
      // 如果是从物料库发起则该数据有值
      mateialInfo: {}
    }
  },
  created() {
    this.IDX = parseInt(this.$route.params.idx)
    // 1 首营 2：列表
    this.requestType = parseInt(this.$route.params.type)
    if (this.IDX === -1024) {
      this.mateialInfo = this.$route.params.materialInfo
    }
  },
  mounted() {
  },
  methods: {
    // // 重新发起
    // reLaunchApproval() {
    //   this.$refs.editCertifiList.reLaunchApproval()
    // },
    // // 发起审核
    // LaunchApproval() {
    //   this.SaveOrLanch = 2
    //   this.$refs.MatetialEdit.save()
    // },
    // save() {
    //   this.SaveOrLanch = 1
    //   this.$refs.MatetialEdit.save()
    // },
    // 获取人员信息
    async getUserinfo() {
      const _this = this
      _this.editProductLoading = true
      await $post.getUserInfo().then((res) => {
        _this.editProductLoading = false
        if (res.RetCode === '0') {
          _this.UserInfo = res.Message
        }
      })
    },
    cancle() {
      if (this.requestType === 1) {
        this.$router.push({ name: 'productList1' })
      } else if (this.requestType === 2) {
        this.$router.push({ name: 'productInfoList1' })
      }
    }
  }
}
export default productEdit
